
<template>
  <div class="home">
    <Header></Header>
  <div style="margin:0px auto; width: 100%;max-width: 1920px; overflow:hidden;" class="header-box">
<!--    <div style="width: 100%;position: fixed;z-index: 999;left: 0;top: 0 ">-->
<!--      <a href="#" style="display: block; width: 5%; height: 100%; top: 0;left: 34%; position: absolute;"></a>-->
<!--      <a href="#"  @click="goNewMessage"-->
<!--         style="display: block; width: 5%; height: 100%; top: 0;left: 41%; position: absolute;"></a>-->
<!--      <a href="#"  @click="goFeedback"-->
<!--         style="display: block; width:6%; height: 100%; top: 0;left: 49%; position: absolute;"></a>-->
<!--      <a href="#"  @click="goSkills"-->
<!--         style="display: block;width:6%;height: 100%;top: 0;left: 58%;position: absolute;"></a>-->
<!--      <a href="https://club.lenovo.com.cn/forum-1349-1.html" target="_blank"-->
<!--         style="display: block;width:6%;height: 100%;top: 0;left: 65%;position: absolute;"></a>-->
<!--      <a href="https://activity.lenovo.com.cn/xiaofei/pingban/xxpb.index.html" target="_blank"-->
<!--         style="display: block;width:6%;height: 100%;top: 0;left: 72%;position: absolute;"></a>-->

<!--      <img src="../assets/images/1.jpg" style="border:0px;display:block;width:100%;">-->
<!--    </div>-->
<!--    <div style="width: 100%;max-width: 1920px;opacity: 0">-->
<!--      <img src="../assets/images/1.jpg" style="border:0px;display:block;width:100%;">-->
<!--    </div>-->

    <img src="../assets/images/2.jpg" style="border:0px;display:block;width:100%;"/>
    <img src="../assets/images/3.jpg" style="border:0px;display:block;width:100%;"/>
    <img src="../assets/images/4.jpg" style="border:0px;display:block;width:100%;"/>
    <img src="../assets/images/5.jpg" style="border:0px;display:block;width:100%;"/>
    <img src="../assets/images/6.jpg" style="border:0px;display:block;width:100%;"/>
    <img src="../assets/images/7.png" style="border:0px;display:block;width:100%;"/>
    <img src="../assets/images/8.png" style="border:0px;display:block;width:100%;"/>
    <img src="../assets/images/9.jpg" style="border:0px;display:block;width:100%;"/>
    <img src="../assets/images/10.jpg" style="border:0px;display:block;width:100%;"/>
    <img src="../assets/images/11.jpg" style="border:0px;display:block;width:100%;"/>
    <img src="../assets/images/12.jpg" style="border:0px;display:block;width:100%;"/>
    <img src="../assets/images/13.jpg" style="border:0px;display:block;width:100%;"/>
    <img src="../assets/images/14.jpg" style="border:0px;display:block;width:100%;"/>
    <div style="width: 100%;position: relative; " class="aphover">
      <!-- 【底部导航】
新浪微博：https://weibo.com/izuk
官方微信：稍后更新
云服务：https://www.zui.com/cloudservice
解锁：https://www.zui.com/iunlock
开发者计划：https://cdnzui.lenovomm.cn/web/book/Lenovo_Tablet_Document.zip -->
      <a href="https://weibo.com/izuk" target="_blank"
         style="display: block; width: 6%; height: 100%; top: 0;left: 17%; position: absolute;">

      </a>
      <!-- 微信链接--> <a href="#" target="_blank"
                          style="display: block; width: 6%; height: 100%; top: 0;left: 33%; position: absolute;">
      <!-- 微信弹窗 -->
      <div class="tckhover">
        <img src="../assets/images/erwei-code.png">
      </div>
    </a>
      <a href="https://www.zui.com/cloudservice" target="_blank"
         style="display: block; width:6%; height: 100%; top: 0;left: 48%; position: absolute;"></a>
      <a href="https://www.zui.com/iunlock" target="_blank"
         style="display: block; width:6%; height: 100%; top: 0;left: 62%; position: absolute;"></a>
      <a href="https://cdnzui.lenovomm.cn/web/book/Lenovo_Tablet_Document.zip " target="_blank"
         style="display: block; width:6%; height: 100%; top: 0;left: 77%; position: absolute;"></a>
      <img src="../assets/images/15.jpg" style="border:0px;display:block;width:100%;"/>


    </div>
    <div style="width: 100%; position: relative;">
      <a target="_blank" href="https://beian.miit.gov.cn/?spm=a2c4g.11186623.J_9220772140.115.75c446f3OnNiw6#/Integrated/index" style="display: block; width: 9%; height: 30%; top: 18%; left: 57%; position: absolute;"></a>
      <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank" style="display: block; width: 14%; height: 30%; top: 50%; left: 43%; position: absolute;"></a>
      <img src="../assets/images/16.jpg" style="border:0px;display:block;width:100%;"/>
    </div>
  </div>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
export default {
  name: 'index',
  components: {Header},
  data() {
    return {

    }
  },
  methods: {
    goNewMessage() {
      this.$router.push({
        name: 'newMessage',
      })
    },
    goFeedback() {
      this.$router.push({
        name: 'feedback',
      })
    },
    goSkills() {
      this.$router.push({
        name: 'skillsMain',
      })
    },
  }
}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}


.aphover a {
  opacity: 0;
  transition: 0.1s;
  background-color: #fff;
}

.aphover a:hover {
  opacity: 1;


}

.aphover a:nth-child(1) {
  background-position: 49% 46.5%;
  background-image: url(../assets/images/5.png);
  background-repeat: no-repeat;
  background-size: 73%;
}

/* 微信弹窗 */
.tckhover {
  width: 230%;
  position: absolute;
  top: -133%;
  left: -66%;
}

.tckhover img {
  width: 100%;
}


.aphover a:nth-child(2) {
  background-image: url(../assets/images/1.png);
  background-repeat: no-repeat;
  background-size: 76%;
  background-position: 4% 46%;


}

.aphover a:nth-child(3) {
  background-image: url(../assets/images/2.png);
  background-repeat: no-repeat;
  background-size: 76%;
  background-position: -6% 48%;

}

.aphover a:nth-child(4) {
  background-image: url(../assets/images/3.png);
  background-repeat: no-repeat;
  background-size: 76%;
  background-position: 9% 48%;


}

.aphover a:nth-child(5) {
  background-image: url(../assets/images/4.png);
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: -88% 45%;


}
</style>
